import React, { Fragment } from "react";
import NavBar from "../components/NavBar.jsx";
import TitleBox, { TitleBoxHeights } from "../components/TitleBox.jsx";
import {
  GridItemCardList,
  CardListContainer,
} from "../components/MainPageGridItem.jsx";
import ResponsiveItemCard from "../components/ResponsiveItemCard.jsx";
import { graphql } from "gatsby";
import Grid from "@material-ui/core/Grid";
import Footer from "../components/Footer.jsx";
import { Helmet } from "react-helmet";

/** @jsx jsx */
import { jsx, Text } from "theme-ui";

export const query = graphql`
  query($draft: Boolean!) {
    keystone {
      allServices(
        sortBy: [homeShow_DESC, title_ASC]
        where: { draft: $draft }
      ) {
        title
        coverImage {
          publicUrl
        }
        slug
        draft
      }
    }
  }
`;

const ServicesPageTemplate = ({ data }) => {
  const services = data.keystone.allServices;

  return (
    <Fragment>
      <Helmet>
        <title>Servizi - Poliambulatorio Giano</title>
        <meta
          name="description"
          content="I numero servizi offerti dal nostro poliambulatorio"
        />
      </Helmet>
      <NavBar />
      <TitleBox heights={TitleBoxHeights.contentPageHeights}>
        <Text variant="pagesTitle">I Nostri Servizi</Text>
      </TitleBox>
      <Grid container>
        <GridItemCardList colorNumber={0}>
          {services.map((x, i) => (
            <CardListContainer queryText={x.title} key={i} navigate={x.slug}>
              <ResponsiveItemCard
                title={x.title}
                image={x.coverImage ? x.coverImage.publicUrl : undefined}
              />
            </CardListContainer>
          ))}
        </GridItemCardList>
      </Grid>
      <Footer />
    </Fragment>
  );
};

export default ServicesPageTemplate;
